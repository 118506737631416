@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth scroll-p-[90px];
  }

  #root {
    @apply flex flex-col min-h-screen overflow-x-clip relative isolate;
  }
}

.checkbox.checkbox-white {
  --checkbox-bg: white;
  --checkbox-border: white;
}

.checkbox.checkbox-black {
  --checkbox-border: black;
}

.checkbox {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  background-color: var(--checkbox-bg);
  color: black;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid var(--checkbox-border);
  border-radius: 0.15em;
  transform: translateY(-0.08em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.checkbox::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  box-shadow: inset 1em 1em var(--checkbox-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.checkbox:checked::before {
  transform: scale(1);
}

.Toastify__toast-icon {
  width: 36px !important;
}

.Toastify__close-button > svg {
  width: 24px !important;
  height: 24px !important;
}

/* Target the scrollbar for elements with the class 'red-scrollbar' */
.red-scrollbar::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  height: 6px; /* Set the height for horizontal scrollbar */
}

/* Style the scrollbar track */
.red-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the track */
}

/* Style the scrollbar thumb */
.red-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f3d0d7; /* Red color for the scrollbar thumb */
  border-radius: 6px; /* Round the edges of the thumb */
}

/* Change the thumb color on hover */
.red-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #edc6cf; /* Darker red when hovered */
}
